import Header from "../../components/header/Header";
import './Code.css';

function codes (){
    return (
        <div className="code-background-teman">
        <div className="special-code-page">
            <Header></Header>
            <div className="special-code-container">
                <h1>Wow! Een speciale code pagina!</h1>
                <p>Voer de volgende code in om punten op te halen:</p>
                <h4>TemanSejatiProductions2</h4>
                <img src="" alt=""/>
            </div>
        </div>
        </div>
    )
}

export default codes;
