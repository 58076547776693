import React, {useContext, useState} from 'react';
import './App.css';
import NavBar from './components/navBar/NavBar';
import Footer from './components/footer/Footer';
import Quiz from './pages/quiz/Quiz';
import PuntenTelling from './pages/PuntenTelling/PuntenTelling';
import NotFound from './NotFound'
import {
    Route, Routes,
} from 'react-router-dom';
import Home from "./pages/home/Home";
import Admin1 from "./pages/admin/Admin1";
import MijnTeam from "./pages/mijnTeam/MijnTeam";
import Inloggen from "./pages/accountStuff/Inloggen";
import SignUp from "./pages/accountStuff/SignUp";
import Info from "./pages/info/info";
import {AuthContext} from "./context/AuthContext";
import Code from "./pages/special code pages/Code";
import Codezz from "./pages/special code pages/Codezz";
import VuurCode from "./pages/special code pages/VuurCode";
import Duisternis from "./pages/special code pages/Duisternis";
import Bosn from "./pages/special code pages/Bosn";

function App() {
    // const [isVerified, setIsVerified] = useState(false);
    const {locatie, isAuth} = useContext(AuthContext)
    // const checkPw = () => {
    //     // gets the current input value
    //     const answer = document.getElementById("password").value;
    //     // if (answer === "Danken") {
    //     //     setIsVerified(true);
    //     // } else {
    //     //     alert("Maar dat klopt toch helemaal niet man!");
    //     // }
    // }
    return (
        <>
            {/*{isVerified ?*/}
                <div><NavBar/>
                    <Routes>
                        <Route path="/" exact element={<Home/>}/>
                        <Route path="/quiz" element={<Quiz/>} />
                        <Route path="/puntentelling" element={<PuntenTelling/>} />
                        <Route path="/mijn-team" element={<MijnTeam/>}/>
                        <Route path="*" element={<NotFound/>}/>
                        <Route path="/admin1" element={<Admin1/>}/>
                        <Route path="/inloggen" element={<Inloggen/>}></Route>
                        <Route path="/signup" element={<SignUp/>}></Route>
                        {isAuth &&
                        <Route path="/code" element={<Code/>} ></Route>}
                        {isAuth &&
                        <Route path="/codezz" element={<Codezz/>}></Route>
                        }
                        {isAuth &&
                            <Route path="/vuurcode" element={<VuurCode/>}></Route>
                        }
                        {isAuth &&
                            <Route path="/duisternis" element={<Duisternis/>}></Route>
                        }
                        {isAuth &&
                            <Route path="/bosn" element={<Bosn/>}></Route>
                        }
                        {locatie &&
                            <Route path="/info" element={<Info/>}></Route>}
                    </Routes>
                    <Footer/>
                </div>
            {/*//     :*/}
            {/*//     (*/}
            {/*//         <form onSubmit={checkPw} className='inlogform-website'>*/}
            {/*//             <input id="password" name="password" />*/}
            {/*//             <button className='button'>Let's Go</button>*/}
            {/*//         </form>*/}
            {/*//     )*/}
            {/*// }*/}
        </>
    );
}
export default App;
