import React, {createContext, useEffect, useState} from "react";
import { useNavigate} from 'react-router-dom';
import axios from "axios";
import jwtDecode from "jwt-decode";

export const AuthContext = createContext({});

function AuthContextProvider({children}){
    const navigate = useNavigate();
    const [auth, toggleAuth] = useState({
        isAuth: false,
        locatie: false,
        user: {name: null,
        team: 'white', id: null},
        status:'pending',

    });

    async function fetchLoca () {
        try{
            axios.get('https://hartelijk-danken.love/api/locatie').then(function(response) {
                toggleAuth(prevState => ({
                    ...prevState,
                    locatie: response.data.locatie
                }));
            });
        } catch (e) {
            console.error(e)
        }
    }

    useEffect(()=>{
        const token = localStorage.getItem('token');
        fetchLoca()
        if(token != null) {
            const decodedToken = jwtDecode(token);
            getUserData(decodedToken.sub, token)
        } else {
            toggleAuth({
                ...auth,
                isAuth: false,
                status: 'done',
                user: {name: null,
                    team: 'white', id: null},
                locatie: false
            }
            );
        }
    }, []);

    async function getUserData(user, token){
        await fetchLoca()
        try {
            const response = await axios.get(`https://hartelijk-danken.love/players/id`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    name: user
                }
            });
            toggleAuth({
                ...auth,
                isAuth: true,
                user: {
                    name:response.data.player.name,
                    team: response.data.player.team,
                    id: response.data.player.id
                },
                status: 'done'
            });
        } catch (e) {
            localStorage.clear();
            console.error(e);
            toggleAuth({
                ...auth,
                status: 'error',
            });
        }
    }

    function logIn(token){
        const decodedToken = jwtDecode(token);
        localStorage.setItem('token', token)
        getUserData(decodedToken.sub, token)
        fetchLoca()
        navigate('/profile')
    }
    function logOut(){
        toggleAuth({
            isAuth: false,
            user: null,
            status: 'done',
        });
        localStorage.clear();
        navigate('/')
    }
    async function toggleLocatie () {
        try{
            const response = await axios.post('https://hartelijk-danken.love/api/toggleloca')
            toggleAuth({
                ...auth,
                locatie: response.data.locatie
            });
        } catch (e) {
            console.error(e)
        }
    }
    const contextData = {
        isAuth: auth.isAuth,
        user: auth.user,
        logInFunction: logIn,
        logOutFunction: logOut,
        locatieToggle: toggleLocatie,
        locatie: auth.locatie,
    }
    return (
        <AuthContext.Provider value={contextData}>
            {auth.status === "done" && children}
            {auth.status === "pending" && <p>Aan het laden...Dit kan niet lang duren!</p>}
            {auth.status === "error" && <p>Er ging iets mis! Refresh de pagina..</p>}
        </AuthContext.Provider>
    )
}
export default AuthContextProvider;