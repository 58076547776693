import './Quiz.css';
import {useContext, useEffect, useState} from "react";
import {useForm} from 'react-hook-form';
import Header from "../../components/header/Header";
import {AuthContext} from "../../context/AuthContext";
import axios from "axios";
import jwtDecode from "jwt-decode";
import {Link} from "react-router-dom";

function Quiz(){
    const {isAuth, user} = useContext(AuthContext);
    const [success, toggleSuccess] = useState(false)
    const [points, setPoints] = useState(0);
    const [quizGemaakt, toggleQuizGemaakt] = useState(1)
    const { register, handleSubmit, formState: {errors},} = useForm();
    const [answers, setAnswers] = useState([]);
    const [pointMessage, setPointMessage] = useState("Lekker!")
    const saveAnswersToLocalStorage = (answer) => {
        console.log(answer)
        const savedAnswers = localStorage.getItem('quizAnswers');
        const existingAnswers = savedAnswers ? JSON.parse(savedAnswers) : [];
        const updatedAnswers = existingAnswers.filter(
            (existingAnswer) => existingAnswer.id !== answer.id
        );

        // Append the new answer to the existing answers array
        updatedAnswers.push({ id: answer.id, value: answer.value });

        // Save the updated answers array to local storage
        localStorage.setItem('quizAnswers', JSON.stringify(updatedAnswers));
    };
    const loadAnswersFromLocalStorage = () => {
        const savedAnswers = localStorage.getItem('quizAnswers');
        if (savedAnswers) {
            setAnswers(JSON.parse(savedAnswers));
        }
    };
    useEffect(()=>{
        loadAnswersFromLocalStorage()
    }, []);

    useEffect(()=>{
        const token = localStorage.getItem('token');
        if(token != null) {
            const decodedToken = jwtDecode(token);
            fetchQuizGemaakt(decodedToken.sub, token)
        }
    }, []);
    useEffect(()=>{
        if (points < 15){
            setPointMessage("Leer je vrienden is kennen joh!")
        } else if (points > 30){
            setPointMessage("Een echte Dankenaar!")
        } else {
            setPointMessage("Lekker!")
        }
    }, [points])
    async function fetchQuizGemaakt (user, token) {
        try{
            const response = await axios.get(`https://hartelijk-danken.love/players/id`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    name: user
                }
            });
            toggleQuizGemaakt(response.data.player.quiz_gemaakt)
            setPoints(response.data.player.quiz_gemaakt)
            console.log(response.data.player.quiz_gemaakt)
            }catch(e){
            console.error(e)
        }
            }

    const multipleChoice = [
        {question: '1.',
            options: [
                {value:'A', label: 'A'},
                {value:'B', label: 'B'},
                {value:'C', label: 'C'},
                {value:'D', label: 'D'}
            ]
        },
        {question: '2.',
            options: [
                {value:'A', label: 'A'},
                {value:'B', label: 'B'},
                {value:'C', label: 'C'},
                {value:'D', label: 'D'}
            ]
        },
        {question: '3.',
            options: [
                {value:'A', label: 'A'},
                {value:'B', label: 'B'},
                {value:'C', label: 'C'},
                {value:'D', label: 'D'}
            ]
        },
        {question: '4.',
            options: [
                {value:'A', label: 'A'},
                {value:'B', label: 'B'},
                {value:'C', label: 'C'},
                {value:'D', label: 'D'}
            ]
        },
        {question: '5.',
            options: [
                {value:'A', label: 'A'},
                {value:'B', label: 'B'},
                {value:'C', label: 'C'},
                {value:'D', label: 'D'}
            ]
        },
        {question: '6.',
            options: [
                {value:'A', label: 'A'},
                {value:'B', label: 'B'},
                {value:'C', label: 'C'},
                {value:'D', label: 'D'}
            ]
        },
        {question: '7.',
            options: [
                {value:'A', label: 'A'},
                {value:'B', label: 'B'},
                {value:'C', label: 'C'},
                {value:'D', label: 'D'}
            ]
        },
        {question: '8.',
            options: [
                {value:'A', label: 'A'},
                {value:'B', label: 'B'},
                {value:'C', label: 'C'},
                {value:'D', label: 'D'}
            ]
        },
        {question: '9.',
            options: [
                {value:'A', label: 'A'},
                {value:'B', label: 'B'},
                {value:'C', label: 'C'},
                {value:'D', label: 'D'}
            ]
        },
        {question: '10.',
            options: [
                {value:'A', label: 'A'},
                {value:'B', label: 'B'},
                {value:'C', label: 'C'},
                {value:'D', label: 'D'}
            ]
        },
        {question: '11.',
            options: [
                {value:'A', label: 'A'},
                {value:'B', label: 'B'},
                {value:'C', label: 'C'},
                {value:'D', label: 'D'}
            ]
        },
        {question: '12.',
            options: [
                {value:'A', label: 'A'},
                {value:'B', label: 'B'},
                {value:'C', label: 'C'},
                {value:'D', label: 'D'}
            ]
        },
        {question: '13.',
            options: [
                {value:'A', label: 'A'},
                {value:'B', label: 'B'},
                {value:'C', label: 'C'},
                {value:'D', label: 'D'}
            ]
        },
        {question: '14.',
            options: [
                {value:'A', label: 'A'},
                {value:'B', label: 'B'},
                {value:'C', label: 'C'},
                {value:'D', label: 'D'}
            ]
        },
        {question: '15.',
            options: [
                {value:'A', label: 'A'},
                {value:'B', label: 'B'},
                {value:'C', label: 'C'},
                {value:'D', label: 'D'}
            ]
        },
        {question: '16.',
            options: [
                {value:'A', label: 'A'},
                {value:'B', label: 'B'},
                {value:'C', label: 'C'},
                {value:'D', label: 'D'}
            ]
        },
        {question: '17.',
            options: [
                {value:'A', label: 'A'},
                {value:'B', label: 'B'},
                {value:'C', label: 'C'},
                {value:'D', label: 'D'}
            ]
        },
        {question: '18.',
            options: [
                {value:'A', label: 'A'},
                {value:'B', label: 'B'},
                {value:'C', label: 'C'},
                {value:'D', label: 'D'}
            ]
        },
        {question: '19.',
            options: [
                {value:'A', label: 'A'},
                {value:'B', label: 'B'},
                {value:'C', label: 'C'},
                {value:'D', label: 'D'}
            ]
        },
        {question: '20.',
            options: [
                {value:'A', label: 'A'},
                {value:'B', label: 'B'},
                {value:'C', label: 'C'},
                {value:'D', label: 'D'}
            ]
        },
    ]


    async function updateScore(counter, antwoorden) {
        const scoreUpdateDto = {
        id: user.id,
        player_score: parseInt(counter),
            quiz_gemaakt: counter,
            antwoorden: antwoorden
    }
        try {
            console.log(scoreUpdateDto)
            const response = await axios.put('https://hartelijk-danken.love/players/update-score', scoreUpdateDto)
            console.log(response)
            toggleSuccess(true)
        } catch (e) {
            console.error(e)
            toggleSuccess(false)
        }

    }

    function whoWins (data){
        console.log("Hier gheeburt ietss!")
        console.log(data)
        let antwoorden = data
        let counter = 0;
        if (data[1] === 'C'){
            counter += 5;
        }
        if (data[2] === 'D'){
            counter += 5;
        }
        if (data[3] === 'C'){
            counter += 5;
        }
        if (data[4] === 'B'){
            counter += 5;
        }
        if (data[5] === 'A'){
            counter += 5;
        }
        if (data[6] === 'D'){
            counter += 5;
        }
        if (data[7] === 'C'){
            counter += 5;
        }
        if (data[8] === 'D'){
            counter += 5;
        }
        if (data[9] === 'B'){
            counter += 5;
        }
        if (data[10] === 'C'){
            counter += 5;
        }
        if (data[11] === 'C'){
            counter += 5;
        }
        if (data[12] === 'A'){
            counter += 5;
        }
        if (data[13] === 'B'){
            counter += 5;
        }
        if (data[14] === 'A'){
            counter += 5;
        }
        if (data[15] === 'B'){
            counter += 5;
        }
        if (data[16] === 'B'){
            counter += 5;
        }
        if (data[17] === 'C'){
            counter += 5;
        }
        if (data[18] === 'B'){
            counter += 5;
        }
        if (data[19] === 'C'){
            counter += 5;
        }
        if (data[20] === 'A'){
            counter += 5;
        }
        setPoints(counter)
        //     Hier moet iets gaan gebeuren waar de winnaar wordt uitgekozen ...
        updateScore(counter, antwoorden)
        toggleQuizGemaakt(counter)
    }
    return(
        <>

            <div>
                <div className='quizbox'>
                    <Header></Header>
                    {!isAuth &&
                        <div className='quiz-container'>
                            <div className="quiz-inner-container-text">
                                <h1>De vriendschappen Quiz</h1>
                            <p>Je moet eerst even inloggen om de quiz te zien en te maken</p>

                            <Link to="/inloggen" >
                                <button
                                    className="gekke-knop"
                                >Inloggen
                                </button>
                            </Link>
                            </div>
                        </div>
                    }
                    {isAuth && quizGemaakt <= 0 &&
                    <div className='quiz-container'>

                        <div className="quiz-inner-container-text">
                            <h1>De Vriendschappen Quiz</h1>
                            <h2>Let op!</h2>
                            <p>Je kan de quiz maar 1 keer maken. Wacht dus totdat de vragen worden gesteld, anders heb je een grote kans dat je punten misloopt...</p>
                            <p>Wij gaan jullie vragen stellen. Dus let goed op en vul de vragen hieronder in : </p>
                        </div>

                        {
                            // !answers.length > 0 &&
                            <form className='quiz' onSubmit={handleSubmit(whoWins)}>
                                {multipleChoice.map(({ question, options}, index) => (
                                    <div key={question} className='question'>
                                        <h3>{question}</h3>
                                        <div className='answers'>
                                        {options.map(({value, label}) => (
                                            <div key={value}>
                                                <label>{label} </label>
                                                <input
                                                    type="radio"
                                                    id={question}
                                                    value={value}
                                                    {...register(question, {
                                                        required: "Gaarne alles invullen joggie!",
                                                        onChange: (e) => {
                                                            saveAnswersToLocalStorage({
                                                                id: index + 1, // Add 1 to the index to make it 1-based question number
                                                                value: e.target.value
                                                            });
                                                        },
                                                    })}
                                                    checked={answers.find(
                                                        (answer) => answer.id === index + 1 && answer.value === value
                                                    )
                                                }
                                                />

                                            </div>
                                        ))}
                                        {errors[question] && <h2>{errors[question].message}</h2>}
                                    </div>
                                    </div>
                                ))}
                                <div className="quiz-button-container">
                                    <h3>Als je alles hebt ingevuld mag je hieronder klikken!</h3>
                                    <button type='submit' className="gekke-knop">Klaar!</button>
                                    {Object.keys(errors).length > 0 && <div className="errors"><p>Je bent wat vergeten! (sukkel)</p></div>}
                                    {success && <h2>Gelukt!</h2>}
                                </div>
                            </form>
                        }
                    </div>}
                    {isAuth && points > 0 &&
                    <div className="quiz-punten-container">
                    <h1>Je punten : {points}</h1>
                        <h3>{pointMessage}</h3>
                        <h4>Deze worden automatisch aan je score toegevoegd</h4>
                        <Link to="/mijn-team">
                            <button className="gekke-knop">Hoe staat het ervoor?</button>
                        </Link>
                    </div>
                    }
                </div>
            </div>
        </>


    )
}
export default Quiz;