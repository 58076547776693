import './NavBar.css';
import {Link, NavLink} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import {ReactComponent as BlackLogo} from "../../Tijdelijk-logo.svg";
import burgerIcon from '../../588a6507d06f6719692a2d15.png';
import {AuthContext} from "../../context/AuthContext";
import {ReactComponent as LocationIcon} from '../../assets/drive-download-20230516T134924Z-001/geography.svg'
import {ReactComponent as TrophyIcon} from '../../assets/drive-download-20230516T134924Z-001/trophy.svg'

function NavBar (){
    const {isAuth, user, locatie} = useContext(AuthContext);
    const [themeColor, setTeamColor] = useState('white')

    // const themeColor = user.team;
    const [isExpanded, setExpanded] = useState(false);

    useEffect(() => {
        if (user) {
            setTeamColor(user.team)
        }
    },[user]);

    function handleToggle(e){
        e.preventDefault();
        setExpanded(!isExpanded);
    }
    function handleNavLinkClick() {
        setExpanded(false);
    }
    return(

        <nav className="navbar" id={`${themeColor}`}>
            {/*<div className={`${themeColor}`}>*/}
            {/*    <div >*/}
            <div className='logo'>
                <Link to='/'>
                <BlackLogo
                    width='150px' height='100%'/>
            </Link>
            </div>

            <div className='navbar-container'>
                <img src={burgerIcon} alt="menu"
                    className="hamburger-menu"
                    aria-hidden="true"
                    onClick={e => handleToggle(e)}
                />
                
                <ul className={`collapsed ${isExpanded ? "is-expanded" : ""}`}>
                    <li>
                        <NavLink to="/" className={({isActive}) => isActive === true ? 'active-link' : 'default-link'} onClick={handleNavLinkClick}>Home</NavLink>
                    </li>
                    <li>
                        <NavLink to="/quiz" className={({isActive}) => isActive === true ? 'active-link' : 'default-link'} onClick={handleNavLinkClick}>Quiz</NavLink>
                    </li>
                    <li>
                        <NavLink to="/puntentelling" className={({isActive}) => isActive === true ? 'active-link' : 'default-link'} onClick={handleNavLinkClick}>PuntenTelling</NavLink>
                    </li>
                    {locatie && isAuth &&
                        <li>
                            <NavLink to="/info" className={({isActive}) => isActive === true ? 'active-link' : 'default-link'} onClick={handleNavLinkClick}>Info</NavLink>
                        </li>
                    }
                    {!isAuth &&
                        <Link to="/inloggen" >
                            <button
                            className="gekke-knop"
                            onClick={handleNavLinkClick}
                            >Inloggen
                        </button>
                        </Link>
                    }
                    { isAuth &&
                        <div><Link to="/mijn-team">
                        <button className="gekke-knop" onClick={handleNavLinkClick} >Mijn Team</button>
                    </Link></div>}
                </ul>
            </div>
            {/*    </div>*/}
            {/*</div>*/}
        </nav>

    )

} export default NavBar;