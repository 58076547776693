import React, {useContext, useEffect, useState} from "react";
import {AuthContext} from "../../context/AuthContext";
import "./MijnTeam.css";
import Header from "../../components/header/Header";
import axios from "axios";
import {Link, useNavigate} from "react-router-dom";
import {ScoreContext} from "../../context/ScoreProvider";


function MijnTeam() {
    const [players, setPlayers] = useState([])
    const {isAuth, user,
        logOutFunction
    } = useContext(AuthContext);
    const {teamRed, teamBlue} = useContext(ScoreContext);
    const navigate = useNavigate();
    const [themeColor, setThemeColor] = useState(user.team)
    const [teamPoints, setTeamPoints] = useState(0)
    const [uitlogToggle, setUitlogToggle] = useState(false)
    const [uitlogMessage, setUitlogMessage] = useState("")
    const [nick, toggleNick] = useState(false)
    const [name, setName] = useState(null);
    const [punten, setPunten] = useState(0);

    function getTotal() {
        const teamPoints = themeColor === 'red' ? teamRed.team_score : teamBlue.team_score;
        if (teamPoints <= 100){
            setTeamPoints(teamPoints + " (Nog even jullie best doen dus ...)")
        }  else if (teamPoints <= 200){
            setTeamPoints(teamPoints + " (Lekker groeien!)")
        } else if (teamPoints <= 300) {
            setTeamPoints(teamPoints + " (kk ziek!)")
        } else {
            setTeamPoints(teamPoints + " (Zijn jullie stiekem vals aan het spelen?)")
        }
    }
    function uitloggen(){
        setUitlogToggle(!uitlogToggle)
        setUitlogMessage("Weet je zeker dat je de Danken App Wilt verlaten?")
    }
    function nickBartkowski(){
        toggleNick(!nick)
    }
    useEffect(() => {
        if (user) {
            setThemeColor(user.team)
        }
    },[user]);

    useEffect(() => {
        async function fetchData() {
            try {
                getTotal()
                const response = await axios.get(`https://hartelijk-danken.love/players/${themeColor}`)
                setPlayers(response.data)
            } catch (e) {
                console.error(e)
            }
        }

        fetchData();
    }, [])

    return (
        <div className='body-container'>
            <Header></Header>
            {!isAuth && !nick &&
                <section className="welcome">
                    <h2>Welkom op de profiel pagina</h2>
                    <h2>Laten we eerst even inloggen!</h2>
                    <button
                        type="button"
                        onClick={() => navigate('/inloggen')}
                    >
                        Inloggen
                    </button>
                </section>}
            {isAuth && user && !nick &&
                <div className="team-Container">
                    <div className='team-inner-container'>
                    <h2>Hoi {user.name}!</h2>
                    <h3>Hier kun je zien hoe jullie team ervoor staat.</h3>
                    </div>
                    <section className="team-details-container"
                             id={`${themeColor}`}
                    >
                        <h1>Team {user.team}</h1>
                        <table>
                            <tbody>
                            {players.map((player) => {
                                return <tr key={player.name}>
                                    <td><h3>{player.name}</h3></td>
                                    <td>{player.player_score} Punten</td>
                                </tr>
                            })
                            }
                            </tbody>
                        </table>
                        <div className="team-points-container"><h3>Totaal: {teamPoints}</h3></div>
                    </section>
                    {!uitlogToggle &&
                        <button
                        className="gekke-knop"
                        type="button"
                        onClick={uitloggen}
                    >
                        Uitloggen
                    </button>
                    }

                    {uitlogToggle &&
                    <div className="uitloggen">
                        <h1>{uitlogMessage}</h1>
                        <button className="gekke-knop"
                                type="button"
                                onClick={nickBartkowski}>Ja (sukkel)</button>
                        <button className="gekke-knop"
                                type="button"
                                onClick={uitloggen}>Nee</button>
                    </div>
                    }

                </div>
            }
            {nick &&
            <div className="nick-bartkovski">
                <h1>Wauw! Je hebt de Nick Bartkowski code gevonden!</h1>
                <p>Voer de volgende code in :</p>
                <h4>DeNieuweNick20</h4>

                <Link to="/">
                    <button className="gekke-knop"
                            type="button"
                            onClick={nickBartkowski}>Terug</button>
                </Link>
                <button className="gekke-knop"
                        type="button"
                        onClick={logOutFunction}>Leuk. Maar ik wil ook uitloggen</button>
            </div>}
        </div>
    )

}

export default MijnTeam;