import {useContext} from "react";
import {AuthContext} from "../../context/AuthContext";
import './info.css'
import {Link} from "react-router-dom";
import Header from "../../components/header/Header";

function Info () {
    const {locatie} = useContext(AuthContext);
    return (
        <div>
            {locatie &&
        <div className="locatie-pagina">
            <Header></Header>
            <div className="header">
                <img src="https://www.lesviviers.be/uploads/slider/32/Marche-en-Famenne%20(1).jpg" alt="Marche-en-Famenne"/>
            {/*<div className="header-text">*/}
            {/*    <p>We gaan naar </p>*/}
            {/*    <h1>Marche-en-Famenne!</h1>*/}
            {/*    <p>Belgie</p>*/}
            {/*</div>*/}
        </div>
            <section className="locatie-text-container">
                <div className="info-container">
                <h3>We gaan naar ...</h3>
                <h1>Marche-en-Famenne!</h1>
                    <h3>Of naja, in de buurt daarvan dan...</h3>
                    <p>Marche-en-Famenne is een stad met 17,000 inwoners en daardoor een van de grootste steden van de Ardennen. Er wordt al sinds de Romeinen groentes en fruit verhandeld. En je kunt nog sporen van Romeinen in de stad zien. Echter niet zo cool als in de Uuuu dus boeit het ons hoogstwaarschijnlijk niks.</p>
                    <p>
                        We verblijven in het dorpje <strong>Lignières,</strong> deel van de gemeente Roy en dus deel van het district Marche-en-Famenne. Dit ligt dan weer in de provincie Luxembourg wat dan weer in Wallonië ligt.
                    </p>
                    <br/>
                    <p>Lignières is een plek met 320 inwoners. In de tweede wereldoorlog is hier flink gevochten en daardoor zie je in en rondom het dorp nog veel gedenkplaatsen. De voormalige kerk is in deze oorlog volledig verwoest. Vermoedelijk door kk duitsers.</p>
                    <p>Oja, en ze praten Frans in dit deel van Belgique... Op de een of andere manier betekent dat ook gelijk dat ze dus geen Engels praten</p>
                </div>
                <div className="article-containers">
                    <div className="line1"> </div>
                    <h3>Wat gaan we doen dan?!</h3>
                    <div className="padding-container"><h1>Zondag :</h1></div>
                <article>
                    <div><p><strong>9:00 - Ontbijt.</strong><br/> We staan op een christelijke tijd op <br/>(Het is immers de dag van de heer).</p></div>
                    <img src="https://media.istockphoto.com/id/485435958/photo/young-happy-family-having-breakfast.jpg?s=612x612&w=0&k=20&c=BiHcpVEm0DQopaZaOy9ghp41XnQCGR4wogaO7db7PO0=" alt="Ontbijtje"/>
                </article>

                <article className="andersom">
                    <div><p><strong>10:00 - De natuur in!</strong><br/>Een wandelingetje onder leiding van Michiel (als die wakker is).</p></div>
                    <img src="https://photo.sunlocation.com/142/142373/142373-42.jpg" alt="Omgeving Verblijf"/>
                    </article>
                <article>
                    <div><p><strong>12:00 - Lunch</strong><br/>We gaan iets kleins snacken in het dorp. Zorg dus dat je genoeg gegeten hebt daarvoor of neem wat mee. </p></div>
                    <img src="https://previews.123rf.com/images/pressmaster/pressmaster1605/pressmaster160501188/57348658-top-view-of-friends-having-lunch.jpg" alt="Ontbijtje"/>
                </article>
                <article className="andersom">
                    <div><p><strong>14:00 - Activiteit</strong><br/>Dit is een sportieve activiteit! Doe ook kleren aan die vies mogen worden.</p></div>
                    <img src="https://st4.depositphotos.com/5392356/24933/i/600/depositphotos_249330752-stock-photo-beautiful-sporty-senior-couple-staying.jpg" alt="Activiteit voor ouderen"/></article>
                    <Link to="/codezz">
                        <article className="andersom">
                    <div><p><strong>16:30 - Activiteit</strong><br/>We komen eerst nog bij het huisje mocht je willen omkleden of een snelle paga.</p></div>
                    <img src="https://photo.sunlocation.com/142/142373/142373-48.jpg" alt="Bisons"/>
                </article>
                    </Link>
                <article>
                    <div><p><strong>18:30 - Eten</strong><br/>Een goeie bodem is belangrijk want we gaan er weer flink tegenaan!</p></div>
                    <img src="https://previews.123rf.com/images/dolgachov/dolgachov1802/dolgachov180201948/96033606-group-of-people-eating-chicken-for-dinner.jpg" alt="Ontbijtje"/>
                </article>
                <article className="andersom">
                    <div><p><strong>20:00 - Spelletjes</strong><br/>Een avond vol plezier waar ook nog veel punten te verdienen zijn!</p></div>
                    <img src="https://www.thetrentonline.com/wp-content/uploads/2014/01/Family-Playing-The-Trent.jpg" alt="Spellen"/></article>
                <article className="andersom">
                    <div><p><strong>00:00 - Prijs Uitreiking</strong><br/>Tot 00:00 kun je dus nog al je codes invullen en punten vinden en verdienen. <br/>Er zijn fantastische prijzen dus zorg ervoor dat je al je punten online hebt staan!</p></div>
                    <img src="https://as1.ftcdn.net/v2/jpg/03/07/47/06/1000_F_307470637_VcnL70oacSCmDJssEtHVe47fbNXDinz4.jpg" alt="Prijsuitreiking"/></article>
                    <br/><div className="padding-container"><h1>Maandag :</h1></div>
                    <article>
                        <div><p><strong>Ontbijt.</strong><br/> Doe lekker rustig aan. Bepaal zelf wanneer je wat doet. Geen haast.<br/></p></div>
                        <img src="https://media.istockphoto.com/id/485435958/photo/young-happy-family-having-breakfast.jpg?s=612x612&w=0&k=20&c=BiHcpVEm0DQopaZaOy9ghp41XnQCGR4wogaO7db7PO0=" alt="Ontbijtje"/>
                    </article>
                    <article className="andersom">
                        <div><p><strong>Klein schoonmaakje</strong><br/>Niet te gek hoor! Vooral de afwas.</p></div>
                        <img src="https://c8.alamy.com/comp/2PRBFEY/dirty-dishes-dont-stand-a-chance-a-young-couple-washing-the-dishes-together-at-home-2PRBFEY.jpg" alt="Omgeving Verblijf"/>
                    </article>
                    <article className="andersom">
                        <div><p><strong>Terugrije</strong><br/>Samen even beslissen hoe en wat. Onderweg nog iets doen? Nog een sapje in Utrecht? Alles kan nog!</p></div>
                        <img src="https://as1.ftcdn.net/v2/jpg/02/60/35/02/1000_F_260350247_eGO8MWYwjIpml40GRWmIZ7tOs2G3iQtD.jpg" alt="Omgeving Verblijf"/>
                    </article>

                </div>
            </section>
            <section>
                <div className="info-container-verblijf">
                    <div className="info-inner-container">
                        <h1>Adres</h1>
                        <div className="adres">
                            <h4>Adres : Rue de Crombin 23, Marche-en-Famenne, Wallonië 6900, België</h4>
                        </div>
                </div>
                    <div className="info-inner-container">
                        <h1>Verblijf</h1>
                        <div>
                        </div><p>We hebben gezocht naar een plek waar we tot rust kunnen komen in de natuur. Vandaar dat we ook voor een soort blokhut hebben gekozen met veel dieren en bos eromheen.</p>
                        <p>Er lopen meerdere groepen herten en 7 bisons rondom het verblijf. Die schrikken niet meer van muziek en dat mag dus zo hard gezet worden als je wilt. Teveel rust is immers ook niet goed.</p>
                        <p>Er is als het goed is genoeg hout om zelfs Max een avondje tevreden te houden.</p>
                        <p></p>

                        <p>Checkout is voor 18:00 uur maandag. Het is aan jullie om te beslissen hoelaat we vertrekken. Dit kan uiteraard ook in de ochtend zijn.</p>
                        <p>Bij vertrek wordt gevraagd of wij de afwas kunnen doen. Schoonmaakkosten zijn verder betaald.</p>
                        <p>Er is een Delhaize (supermarkt) in Marche-en-Famenne.</p>
                        <p>Ook is er een dorp met de naam 'Beffe' op 10km afstand (bekend van het restaurant Beffely Hills).</p>
                    </div>
                </div>
            </section>
        </div>
        }</div>

    )
} export default Info;