import React, {useContext} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {useForm} from "react-hook-form";
import {AuthContext} from "../../context/AuthContext";
import {useState} from "react";
import "./Inloggen.css";
import axios from "axios";
import Header from "../../components/header/Header";

function Inloggen(){
    const [username, setUsername]  = useState('');
    const [password, setPassword] = useState('')

    const {register, handleSubmit, formState: {errors}} = useForm();
    const {logInFunction, isAuth} = useContext(AuthContext);
    const [error, setError] = useState('')

    const navigate = useNavigate();

    const isValid = username !== '' && password !== '';

    // console.log(isAuth)

    async function onLoginFormSubmit(data){
        try{
            const response = await axios.post('https://hartelijk-danken.love/players/login', {
                name: data.username,
                password: data.password,
            })
            console.log(response.data)
            if (response.data.status === 1){
                logInFunction(response.data.accessToken)
                navigate('/')
            }
            else if (response.data.status === 0){
                setError(response.data.message)
            }
        } catch (e) {
            console.error(e)
            setError(e.response.data)
        }
    }
    return(
        <>
                <div className="form-container">
                    <Header></Header>
                    <form
                        className="login-form"
                        onSubmit={handleSubmit(onLoginFormSubmit)}>
                        <h1>Inloggen</h1>
                        <label htmlFor="username">
                            <p>Naam:</p>
                            <input
                                type="text"
                                id="username"
                                placeholder="Bijv. Kalkoenraad"
                                {...register("username")}
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </label>
                        <label htmlFor="pass">
                            <p>Wachtwoord:</p>
                            <input
                                type="password"
                                placeholder="Bijv. Wachtwoord123"
                                {...register("password")}
                                id="password"
                                value={password}
                                minLength="8"
                                required
                                onChange={(e) => setPassword(e.target.value)}/>
                        </label>
                        {error && <p className="error-message">{error}</p>}
                        <button
                            type="submit"
                            className="gekke-knop"
                            disabled={!isValid}
                        >Inloggen
                        </button>
                        {!isAuth && <p>Heb je nog geen account? <Link to="/signup">Registreer</Link> je dan eerst.</p>}
                    </form>
                </div>
        </>
    )
}
export default Inloggen