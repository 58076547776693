import Header from "../../components/header/Header";
import './Code.css';

function codes (){
    return (

        <div className="code-background-duisternis">
            <div className="special-code-page">
                <Header></Header>
                <div className="special-code-container">
                    <h1>Duisternis</h1><br/>
                    <h4>Hieronder volgt helaas geen code of iets waar je misschien wat aan hebt. Maar, een gedichtje over... de duisternis</h4>
                    <h4>Geniet ervan!</h4><br/>
                    <p>In het diepe duister fluister zacht,</p>
                        <p>Een mysterie geeft ons stille kracht.</p>
                    <p>Het duister hult ons in zijn mantel zwart,</p>
                    <p>Waar dromen dansen, magisch en apart.</p>
                        <br/>
                    <p>De maan verlicht de stilte met haar gloed,</p>
                    <p>Terwijl de wereld slaapt in rust en goed.</p>
                    <p> Laat angst en zorgen nu heel even gaan,</p>
                <p> Omarm de duisternis, laat het bestaan.</p>
                        <br/>
                <p>  Want in haar diepte schuilt een waardevol licht,</p>
                <p>  Dat ons de weg wijst naar een nieuw vergezicht.</p>
                    <br/>
                    <p><strong>- ChatGpt (2023)</strong></p>
                    <img src="" alt=""/>
                </div>
            </div>
        </div>
    )
}

export default codes;
