import React, {useState} from "react";
import axios from "axios";
import "./SignUp.css";
import {Link, useNavigate} from "react-router-dom";
import { useForm } from 'react-hook-form';
import Header from "../../components/header/Header";

function SignUp(){
    const [password, setPassword] = useState('')
    const [name, setName]  = useState('');
    const [error, setError] = useState("");
    const [expanded, toggleExpanded] = useState(false);

    const { register, handleSubmit, formState: { errors },} = useForm();

    const navigate = useNavigate();

    const [addSucces, toggleAddSuccess] = useState(false);

    async function addUser(data){
        try{
            const response = await axios.post('https://hartelijk-danken.love/players/signup',{
                    name: data.name,
                    password: data.password,
                }
            )
            if (response.data.status === 1){
                toggleAddSuccess(true)
            }
            else if (response.data.status === 0){
                setError(response.data.message)
            }
        } catch (e) {
            console.error(e)
        }
    }

    function handleItemClick() {
        toggleExpanded(!expanded)
    }

    return(
        <>
                <div className="signup-form-container">
                    <Header></Header>
                    {addSucces === true &&
                        <div className="login-form">
                            <h1>Je account is aangemaakt!</h1>
                            <p>Er is een mail naar je toe gestuurd ter verificatie</p>
                            <p>Nee hoor grapje!</p>
                            <p>Klik hieronder om gelijk in te loggen</p>
                            <button
                                className="gekke-knop"
                                type="button"
                                onClick={() => navigate('/inloggen')}>
                                Inloggen
                            </button>
                        </div>
                    }
                    {!addSucces === true &&
                        <form className="login-form" onSubmit={handleSubmit(addUser)}>
                            <h1>Registreren</h1>
                            <h4>Maak hieronder een nieuw account aan!</h4>
                            <label htmlFor="name">
                                <p>Naam:</p>
                                <input
                                    type="text"
                                    id="name"
                                    placeholder="Bijv. Piemel Pim"
                                    {...register("name", {
                                        required: "Gaarne je naam invullen!",
                                        maxLength: {
                                            value: 17,
                                            message: "Oei, dat is wel een hele lange naam! Kan die niet korter?"
                                        },
                                        minLength: {
                                            value: 2,
                                            message: "Je moet hier natuurlijk wel iets invullen!"
                                        }
                                    })}
                                />
                            </label>
                            {errors.name && <p className="error-message">{errors.name.message}</p>}

                            <label htmlFor="password">
                                <p>Wachtwoord:</p>
                                <input
                                    type="password"
                                    placeholder="Bijv. Willem123"
                                    id="pass"
                                    {...register("password", {
                                        required: "Je moet natuurlijk wel een wachtwoord hebben!",
                                        maxLength: {
                                            value: 20,
                                            message: "Oei, dat is wel een heel lang wachtwoord! Kan die niet korter?"
                                        },
                                        minLength: {
                                            value: 8,
                                            message: "Ik weet zeker dat je wel iets moeilijkers kunt verzinnen!"
                                        },

                                    })}
                                />
                            </label>
                            {errors.password && <p className="error-message">{errors.password.message}</p>}

                            <button
                                type="submit"
                                className="gekke-knop">
                                (S)let's Go!
                            </button>
                            {error && <h1>{error}</h1>}
                            <p>Heb je al een account? <Link to="/inloggen">Naar inloggen!</Link></p>
                            <div className='trust-issues-outer'
                                onClick={() => handleItemClick(1)}>
                            <h5>Ik vertrouw de commissie niet met mijn wachtwoord. <br/> Is dit veilig? Klik hier voor meer info.</h5>
                                {expanded &&
                                <div className="trust-issues">
                                    <h5>De website https://www.hartelijk-danken.love is volledig beveiligd volgens de Oracle Advanced Security normen.
                                    Dit betekent ook dat wachtwoorden en gebruikersnamen volgens de JDBC protocollen worden opgeslagen.
                                    Je wachtwoord wordt voor aankomst in de database volledig versleuteld met een JDBC encriptie. Deze kan alleen in versleutelde vorm (niet zoals Max vanavond) door de admin (tevens de commissie) worden bekeken.
                                    Door middel van een certificate-based-authentication (CBA) token in de local storage van je internet browser worden je gegevens (in versleutelde vorm) door een JDBC driver gecheckt bij elke refresh.
                                    Mochten de gegevens niet meer kloppen of je cookies en cache worden verwijderd. Dan moet je ook opnieuw inloggen.
                                    De commissie heeft dus geen toegang tot je wachtwoord.</h5></div>
                                }
                            </div>
                        </form>}
                </div>
        </>)
}
export default SignUp