import Header from "../../components/header/Header";
import './Code.css';

function codes (){
    return (

        <div className="code-background-bosn">
            <div className="special-code-page">
                <Header></Header>
                <div className="special-code-container">
                    <h1>Wow!<br/>   Een speciale code pagina!</h1><br/>
                    <p>Voer de volgende code in om punten op te halen:</p>
                    <h4>bosneger10</h4>
                    <img src="" alt=""/>
                </div>
            </div>
        </div>
    )
}

export default codes;
