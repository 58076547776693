import './Footer.css'
import React, {useContext} from 'react';
import {Link} from "react-router-dom";
import {AuthContext} from "../../context/AuthContext";

function Footer(){
    const {isAuth, logOutFunction} = useContext(AuthContext);

    return(
        <>
            <div className='footer'>
                    <Link to="/code">
                        <p>Teman Sejati Productions</p>
                    </Link>
                <Link to="/duisternis">
                    <h4>Klikken in de duisternis</h4>
                </Link>
            </div>
        </>
    )
} export default Footer;