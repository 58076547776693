import './PuntenTelling.css';
import Header from "../../components/header/Header";
import {AuthContext} from "../../context/AuthContext";
import {useContext, useEffect, useState} from "react";
import {Link} from "react-router-dom";

function PuntenTelling() {
    const {isAuth, user} = useContext(AuthContext);
    const [themeColor, setTeamColor] = useState('white')
    const [expandedItems, setExpandedItems] = useState([]);

    useEffect(() => {
        if (user) {
            setTeamColor(user.team)
        }
    },[user]);
        const handleItemClick = (index) => {
            if (expandedItems.includes(index)) {
                setExpandedItems(expandedItems.filter((item) => item !== index));
            } else {
                setExpandedItems([...expandedItems, index]);
            }
        };

    return (
        <div className="body-container-puntentelling">
            <Header></Header>
            <div className='story'>
                <h1>Waar kun je allemaal punten mee verdienen?</h1>
                <strong>
                    <p>Je probeert - zowel voor je team als voor jezelf - punten te scoren. Je kan namelijk team prijzen en
                    individuele prijzen winnen.</p><p>Je eigen punten tellen ook mee voor je team.</p>
                </strong>
                <br/>
                <p>In <Link to="/mijn-team">'Mijn Team'</Link> kun je zien wie in je team de meeste punten heeft en hoeveel jullie in totaal
                    hebben.</p><br/>
                <h3>Wats met die punten dann?!</h3><br/>
                <ul>
                    <p><li>Je kan codes/punten winnen door middel van Missies, Beloningen, Easter Eggs, en meer.</li>
                    <li>Kaartjes/Codes kun je op <Link to="/">'hartelijk-danken.love'</Link> invullen (rechtsonderin het scherm).</li>
                    <li>Iedereen krijgt Eigen Missies.</li>
                    <li>Elk team krijgt Team Missies.</li>
                    <li>Er zijn een aantal momenten voor Team Activiteiten die terzijnertijd worden uitgelegd.</li>
                    <li>Wij houden nog extra Punten Kaartjes achter de hand (voor beloningen, straffing of
                        geheime opdrachten).</li>
                    <li>De commissie kan op elk moment punten in mindering brengen.</li></p>
                </ul>
            </div>



            <div className='story-container'>
                <section className={`${themeColor}`}>
                    <div className="line3"></div>
                    <div className="doelen-container">

                        <h1>Team Missies</h1>
                        <div className='white'><h4>Klik op een missie voor meer info</h4><br/></div>
                        <ul>
                                <li className="expandable"onClick={() => handleItemClick(0)}>
                                    <p>Maak het beste optreden</p>
                                    {expandedItems.includes(0) && (
                                        <div className="expanded-info">
                                        <p>Elk team moet een lied, dans of toneelstuk maken. Aan jullie de keus of dit acapella of met begeleiding is. Dit moet ongeveer 5 minuten duren (of langer). De commissie bepaald de winaar. Je mag zelf een van de twee avonden uitkiezen om het te presenteren.</p>
                                    </div>
                                    )}
                                </li>
                                <li className="expandable" onClick={() => handleItemClick(1)}>
                                    <p>Creëer het beste cocktail recept</p>
                                    {expandedItems.includes(1) && (
                                        <div className="expanded-info">
                                            <p>Elk team maakt een cocktail recept. Je kunt ervoor kiezen ingredienten bij te halen of alleen de aanwezige drank te gebruiken. De cocktail mag GEEN harddrugs bevatten. De commissie bepaald de beste.
                                            Geef de cocktail een naam en presenteer deze aan de commissie.</p>
                                        </div>
                                    )}
                                </li>
                            <li className="expandable" onClick={() => handleItemClick(2)}>
                                <p>Win de activiteit</p>
                                {expandedItems.includes(2) && (
                                    <div className="expanded-info">
                                        <p>We gaan Zondag om 14:00 een activiteit doen. Deze kun je als team winnen. Het is aan te raden om dat te doen.</p>
                                    </div>
                                )}
                            </li>
                            <li className="expandable" onClick={() => handleItemClick(3)}>
                                <p>Win de bosactiviteit</p>
                                {expandedItems.includes(3) && (
                                    <div className="expanded-info">
                                        <p>De vroegere <Link to="/bosn">bosnegers</Link> hadden nog geen punten en mannenweekenden. Probeer de <Link to="/bosn">bosnegers</Link> te eren door hier punten op te halen voor je team. Hier maak je de - inmiddels uitgestorven - bosnegers en je team blij mee!</p>
                                    </div>
                                )}
                            </li>
                        </ul>
                    </div>
                </section>

                <div className="doelen-container">
                    <h1>Doelen</h1>

                    <ul>
                        <h4>Deze kunnen door meerdere Dankenaren behaald worden :</h4><br/>

                        <p><li>Draag het hele weekend een piemel tattoo onder je oog</li>
                        <li>Draag het hele weekend een Traantje tattoo onder je oog</li></p><br/>

                        <h4>Elk doel hieronder kan maar door 1 iemand behaald worden!</h4><br/>

                        <p><li>Wees de meest behulpzame Dankenaar</li>
                        <li>Wees de meest vriendelijke Dankenaar</li>
                        <li>Wees het meest sportief</li>
                        <li>Wees het meest dapper</li>
                        <li>Klim in de hoogste boom</li>
                        <Link to="/vuurcode"><li>Maak een mooi vuurtje</li></Link>
                            <li>Haal het meeste hout</li>
                        <li>Maak als eerst een selfie met een vreemd persoon</li>
                        <li>Laat als eerst een bericht achter op de website</li>

                    </p>
                    </ul>
                </div>
                {isAuth &&
                    <div className="teambutton-container">
                        <p>Klik hieronder om de punten te bekijken</p>
                        <Link to="/mijn-team">
                            <button className="gekke-knop">Mijn Team</button>
                        </Link>
                    </div>
                }
            </div>


        </div>
    )
}

export default PuntenTelling;