import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import {useForm} from "react-hook-form";
import {AuthContext} from "../../context/AuthContext";
import "./CodeInput.css"

const CodeInput = () => {
    const {register, handleSubmit, formState: {errors}} = useForm();
    const {user} = useContext(AuthContext);
    const [error, setError] = useState("");
    const [message, setMessage] = useState("");
    const [themeColor, setTeamColor] = useState('white')

    useEffect(() => {
        if (user) {
            setTeamColor(user.team)
        }
    },[user]);

    async function checkCode(data) {
        setMessage("Aan het checken ...")
        setError("")
        const codeCheckDto = {
            team: themeColor,
            code: data.code,
            user: user.name
        }
        console.log(codeCheckDto)
        try {
            const response = await axios.put(`https://hartelijk-danken.love/codes/update`, codeCheckDto)
            console.log(response)
            setMessage(response.data.message)
            if (response.data.points > 0) {
                console.log("Nu hIer!")
                const scoreUpdateDto = {
                    id: user.id,
                    player_score: response.data.points,
                    user: user.name
                }
                console.log(scoreUpdateDto)
                await updateScore(scoreUpdateDto)
            }
        } catch (e) {
            console.error(e)
            setError(e.response.data)
            setMessage("Het is niet helemaal goed gegaan ...");
        }
    }
    async function updateScore(scoreUpdateDto) {
        try {
            setMessage("Je score wordt aangepast met " + scoreUpdateDto.player_score + " punten!")
            const response = await axios.put('https://hartelijk-danken.love/players/update-score', scoreUpdateDto)
            window.location.reload();
        } catch (e) {
            console.error(e)
            setError(e.response.data)
        }
        // toggleSuccess(false)
    }

    return (
        <section>
            <form className={`score-form-container ${themeColor}`} onSubmit={handleSubmit(checkCode)}>
                <h3>Vul hieronder een code in : </h3>
                <label htmlFor="score-form">
                    <input
                        type="text"
                        id='score-form'
                        placeholder='Vul hier je code in'
                        onInput={() => setError("")}
                        {...register("code", {
                            required: "Moet je wel iets invullen, Mogoowl!",
                            minLength: {
                                value: 5,
                                message: "Te kort! Net als jij zometeen ..."
                            },
                            maxLength: {
                                value: 30,
                                message: "Gast, doe ff normaal en vul een geldige code in ..."
                            }
                        })}/>
                </label>

                <button type='submit' className='gekke-knop'>Checken</button>
                {errors.code && <p className='score-form-errors'>{errors.code.message}</p>}
                {error && <p className='errors'>{error}</p>}
                {message && <p className='errors'>{message}</p>}
            </form>

        </section>
    )}

export default CodeInput;
