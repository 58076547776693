import {Link} from "react-router-dom";
import Header from "./components/header/Header";

function NotFound(){
    return(
        <>
            <div>
                <Header>Oei, dit gaat niet helemaal goed ... </Header>
                <h1>Hier is helemaal niks te vinden joh!</h1>
                <h2>Klik hieronder om terug te gaan naar de homepagina</h2>
                <Link to="/"><button>Knopje</button></Link>
        </div>

        </>
    )
} export default NotFound;