import React, {useState, useContext, useEffect} from 'react';
import './Home.css';
import {ScoreContext} from "../../context/ScoreProvider";
import MessageBoard from "../../components/messageBoard/MessageBoard";
import {AuthContext} from "../../context/AuthContext";
import {Link} from "react-router-dom";
import CodeInput from "../../components/codeInput/CodeInput";

function Home() {
    const {isAuth, user} = useContext(AuthContext);
    const {teamRed, teamBlue} = useContext(ScoreContext);
    const [teamColor, setTeamColor] = useState('white')
    const [scoreMessage, setScoreMessage] = useState(" ")

    const blueScore = teamBlue.team_score;
    const redScore = teamRed.team_score;

    function scoreMessageFunction (){
        if (user.team === teamBlue.team_name){
            if (teamBlue.team_score > teamRed.team_score){
                setScoreMessage("Jullie zijn lekker bezig zeg!")
            } else if (teamBlue.team_score === teamRed.team_score){
                setScoreMessage("Het is nog gelijk zo te zien. Alles kan nog gebeuren, maar ga is punten pakken dan!")
            } else if (teamBlue.team_score < teamRed.team_score){
                setScoreMessage(`Jullie zijn momenteel aan het verliezen! Ga is wat doen ${user.name}!`)
            }
        } else if (user.team === teamRed.team_name){
            if (teamRed.team_score > teamBlue.team_score){
                setScoreMessage("Jullie zijn lekker bezig zeg!")
            }else if (teamBlue.team_score === teamRed.team_score){
                setScoreMessage("Het is nog gelijk zo te zien. Alles kan nog gebeuren, maar ga is punten pakken dan!")
            } else if (teamRed.team_score < teamBlue.team_score){
                setScoreMessage(`Jullie zijn momenteel aan het verliezen! Ga is wat doen ${user.name}!`)
            }
        } else {
            console.log(user)
            console.log(teamBlue)
            console.log(teamRed)
        }
    }

    useEffect(() => {
        if (user) {
            setTeamColor(user.team)
            scoreMessageFunction()
        }
    },[user]);

    const [isFormVisible, setIsFormVisible] = useState(false);

    function ActionButton({ onClick }) {
        let buttonText = "Code invoeren"
        buttonText = isFormVisible ? "x" : buttonText
        return (
            <span>
            <button className="action-button" onClick={onClick}>
                <span><h3>{buttonText}</h3></span>
            </button>
            </span>
        );
    }
    const toggleFormVisibility = () => {
        setIsFormVisible(!isFormVisible);
    };

    useEffect(() => {
        if (user) {
            setTeamColor(user.team)
        }
    },[user]);

    return (
        <div className='body-container'>
            <div className="body">
                <div></div>
                <div className='home-header'>
                    <h1>Het Hartelijke Danken Spel</h1>
                </div>
                <div className='line2'></div>
            </div>
            {!isAuth &&
                <section className="signup-message">
                <Link to="/signup" >
                <button
                className="gekke-knop"
                >Klik hier om te beginnen!
                </button>
                </Link>
                </section>}

            {isAuth &&
                <div className="main-container">
                    <div className="main-container-inner">
                        <h2>Welkom {user.name}!</h2>
                    <h4>Wat ongelofelijk fijn dat je er bent! Wij hebben hier enorm naar uitgekeken en we hopen dat jij er ook een beetje zin in hebt!
                    Ook hopen we dat je het spel dat we hebben bedacht een beetje leuk gaat vinden en dat je alles snapt. Lees daarom de pagina <Link to="/puntentelling">'Puntentelling'</Link> goed door! </h4>
                    <h4>Hieronder zie je in ieder geval de scores. Vergeet niet daaronder ook even een leuk berichtje achter te laten.</h4><br/>
                    <h4>Over de score gesproken trouwens ...</h4><h4>{scoreMessage}</h4>
                    </div>

                <div className="scores-container">
                    <div className='line1'></div>
                    <div className="scores-outer-inner">
                    <h2>Score</h2>
                <div className='scores-inner-container'>
                    <div className='blauw'>
                        <h3>Blauw</h3>
                        {blueScore && <h2>{blueScore}</h2>}
                    </div>
                    <div className='rood'>
                        <h3>Rood</h3>
                        {redScore && <h2>{redScore}</h2>}
                    </div>
                </div>
                    <section className="team-link">
                        <h3>Klik hieronder voor de scores van je teamgenoten</h3>
                        <Link to="/mijn-team" >
                        <button
                            className="gekke-knop"
                        >Mijn Team
                        </button>
                    </Link>
                    </section></div>
            </div>
                </div>}
            {isAuth &&
                <MessageBoard/>
            }
            {isAuth &&
            <section className= "button-container" >
                <ActionButton onClick={toggleFormVisibility}/>
                {isFormVisible && (
                    <CodeInput/>
                )}
            </section>}
        </div>
    )
}

export default Home;