import React, {useContext, useEffect, useState} from "react";
import './MessageBoard.css'
import axios from "axios";
import {useForm} from "react-hook-form";
import {AuthContext} from "../../context/AuthContext";

const MessageBoard = () => {
    const [message, setMessage] = useState("");
    const [messages, setMessages] = useState([])
    const {user} = useContext(AuthContext);
    const [userName, setUserName] = useState("Anoniempje")
    const [colour, setColour] = useState("white")
    const [error, setError] = useState("");
    const { register, handleSubmit, formState: {errors}} = useForm();

        async function postMessage (data){

            setMessage(data.message)
            const messageDto = {
                player: userName,
                message: data.message,
                colour: colour
            }
            try{
                const response = await axios.post(`https://hartelijk-danken.love/messages/post-message`, messageDto)
                setError("Je bericht wordt gepost!")
                setMessage("")
            } catch (e) {
                console.error(e)
                setError(e.response.data)
            }
    }

    useEffect(() => {
        async function getMessages () {
            if (user){
            setUserName(user.name)
            setColour(user.team)
        }
            try{
                const response = await axios.get(`https://hartelijk-danken.love/messages/all`)
                setMessages(response.data)
            } catch (e) {
                console.error(e)
                setError(e.response.data)
            }
        }
        getMessages()
    }, [message])

    function addSixHours(time) {
        const date = new Date(`1970-01-01T${time}Z`);

        date.setHours(date.getHours() + 5);

        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');
        const newTime = `${hours}:${minutes}`;

        return newTime;
    }

    return (
        <>
        <div className='message-board'>
            <form onSubmit={handleSubmit(postMessage)}>
                <h1>Message Board</h1>
                <label htmlFor="message">
                <input
                    type="text"
                    id="message"
                    placeholder='Voer hier een leuk berichtje in'
                    onInput={() => setError("")}
                    {...register("message", {
                        required: "Dat is niet echt een bericht he..",
                        maxLength: {
                            value: 145,
                            message: "Moge uwe berichten net als uzelf, korter zijn"
                        }
                    })}
                />
                </label>
                <button type="submit" className='gekke-knop'>Posten</button>
                {!message && errors.message && <p className='errors'>{errors.message.message}</p>}
                {error && <p className='errors'>{error}</p> }
            </form>

            <ul className='berichten'>
                {messages && messages.map((apiMessage, id) => (
                    <li key={id}><div className='user-container'><span className={`user ${apiMessage.colour}`}>{apiMessage.player}:</span></div>{apiMessage.message}<div className='time'> {addSixHours(apiMessage.time)}</div></li>
                ))}
            </ul>
        </div><div className="end-container"></div>
        </>
    );
};

export default MessageBoard;
