import React, {createContext, useEffect, useState} from 'react';
import axios from "axios";

export const ScoreContext = createContext({})

export function ScoreContextProvider({children}){
    const [teamBlue, setTeamBlue] = useState(0);
    const [teamRed, setTeamRed] = useState(0);

 useEffect(()=>{
 fetchData()
 }, []);
 async function fetchData () {
     try{
     axios.get('https://hartelijk-danken.love/api/teams').then(function(response) {
         setTeamRed(response.data[0]);
         setTeamBlue(response.data[1]);
     });
     } catch (e) {
                     console.error(e)
                 }
 }

    const contextData = {
        teamBlue: teamBlue,
        teamRed: teamRed
    }
    return(
        <ScoreContext.Provider
            value={contextData}>
            {children}
        </ScoreContext.Provider>
    )
}
