import './Admin1.css';
import Header from "../../components/header/Header";
import axios from "axios";
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {AuthContext} from "../../context/AuthContext";
import {useContext} from "react";
import {ScoreContext} from "../../context/ScoreProvider";

function Admin1() {
    const [isVerified, setIsVerified] = useState(false);
    const {isAuth, locatie, locatieToggle} = useContext(AuthContext);
    const {teamRed, teamBlue} = useContext(ScoreContext);
    const blueScore = teamBlue.team_score;
    const redScore = teamRed.team_score;
    const [switcher, toggleSwitcher] = useState(locatie)

    const [currentPlayer, setCurrentPlayer] = useState({})
    const {handleSubmit, formState: {errors}} = useForm();
    const [currentTeamColour, setCurrentTeamColour] = useState("red")
    const [redPlayers, setRedPlayers] = useState([])
    const [bluePlayers, setBluePlayers] = useState([])
    const [success, toggleSuccess] = useState(false)
    const [playerScore, setPlayerScore] = useState(10);

    function toggleSwitch() {
        locatieToggle();
        toggleSwitcher(!switcher)
    }

    const handleTeamColorChange = (e) => {
        setCurrentTeamColour(e.target.value);
        const teamColor = e.target.value === 'red' ? redPlayers : bluePlayers
        setPlayer(teamColor[0])
    };
    const handlePlayerChange = (e) => {
        const teamColor = currentTeamColour === 'red' ? redPlayers : bluePlayers
        let player = teamColor.find((player) => player[0] === e.target.value);
        setPlayer(player)
    }
    const handleScoreChange = (e) => {
        setPlayerScore(e.target.value)
    }
    const setPlayer = (player) => {
        setCurrentPlayer({
            id: player[0],
            name: player[1],
            player_score: parseInt(player[2])
        });
    }

    useEffect(()=>{
        async function fetchData (){
            try{
                const response = await axios.get(`https://hartelijk-danken.love/players/all`)
                const redPlayers = response.data.filter(player => player.team === 'red').map(player => [player.id, player.name, player.player_score]);
                setRedPlayers(redPlayers)
                setPlayer(redPlayers[0])
                const bluePlayers = response.data.filter(player => player.team === 'blue').map(player => [player.id, player.name, player.player_score]);
                setBluePlayers(bluePlayers)
            } catch (e) {
                console.error(e)
            }
        }
        fetchData();
    }, [])

    const scoreUpdateDto = {
        id: currentPlayer.id,
        player_score: playerScore,
    }

    async function updateScore(data) {
        try {
            const response = await axios.put('https://hartelijk-danken.love/players/update-score', scoreUpdateDto)
            toggleSuccess(true)
            window.location.reload();
        } catch (e) {
            console.error(e)
        }
        // toggleSuccess(false)
    }
    async function toggleLocatie () {
        try{
            const response = await axios.post('https://hartelijk-danken.love/api/toggleloca')
            console.log(response)
        } catch (e) {
            console.error(e)
        }
    }

    const checkPw = () => {
        // gets the current input value
        const answer = document.getElementById("password").value;

        if (answer === "Danken") {
            setIsVerified(true);
        } else {
            alert("Maar dat klopt toch helemaal niet man!");
        }
    }
    return (
        <div className='admin'>
            <Header></Header>
            {isVerified ?
            <div className='admin-container'>
                <h1>Admin Pagina</h1>
                <h2>Overzicht van de teams :</h2>

                <div className='teams-overzicht-container'>
                    <section className='team-details'>
                <h2>{teamRed.team_name}</h2>
                <h3>Totaal: {redScore}</h3>
                {redPlayers != null &&
                    <table>
                        <tbody>
                        {redPlayers.map((player)=>{
                            return <tr key={player[0]}>
                                <td className='player-name'>{player[1]}</td>
                                <td>{player[2]}</td>
                            </tr>
                        })
                        }
                        </tbody>
                    </table>
                }
                    </section>
                    <section className='team-details'>
                <h2>{teamBlue.team_name}</h2>
                <h3>Totaal: {blueScore}</h3>
                {bluePlayers != null &&
                    <table>
                        <tbody>
                        {bluePlayers.map((player)=>{
                            return <tr key={player[0]}>
                                <td className='player-name'>{player[1]}</td>
                                <td>{player[2]}</td>
                            </tr>
                        })
                        }
                        </tbody>
                    </table>
                }
                    </section>
                </div>

                <form className="score-form-container" onSubmit={handleSubmit(updateScore)}>
                    <h1>Punten Toevoegen</h1>
                    <div className="punten-toevoeg-container">
                        <div>
                    <label htmlFor="teamColourSelect">
                    <select
                        id="teamColourSelect"
                        size="2"
                        value={currentTeamColour}
                        onChange={handleTeamColorChange}>
                        <option value="red">Rood</option>
                        <option value="blue">Blauw</option>
                    </select></label>
                    <label htmlFor="playerSelect">
                        <select
                            id='playerSelect'
                            className='playerSelect'
                            size="5"
                            onChange={handlePlayerChange}>
                            {currentTeamColour === 'red' &&
                                redPlayers.map((player)=>{
                                        return <option key={player[0]} value={player[0]}>{player[1]}</option>
                                    })
                                }
                            {currentTeamColour === 'blue' &&
                                bluePlayers.map((player)=>{
                                        return <option key={player[0]} value={player[0]}>{player[1]}</option>
                                    })
                                }
                        </select>
                    </label>
                    </div>
                    <label htmlFor="playerScore">
                        <h3>Aantal punten</h3>
                        <input type="number"
                        id='playerScore'
                        min='-50'
                        max='50'
                        onChange={handleScoreChange}
                        />
                    </label>
                        {!success && <button type='submit' className='gekke-knop'>Toevoegen</button>}
                    {success &&
                    <h1>GELUKT!</h1>}
                    </div>
                </form>

                <section className='switchbox-loca'>
                    Info pagina aanzetten
                    <label className="switch">
                        <input type="checkbox"
                               checked={switcher} onChange={toggleSwitch}/>
                            <span className="slider round"></span>
                    </label>
                </section>
            </div>
            :
            (
                <form onSubmit={checkPw} className='inlogform-website'>
                    <h2>Deze pagina is echt alleen voor commissie</h2>
                    <input id="password" name="password" placeholder="Wachtwoord"/>
                    <button className='button'>Let's Go</button>
                </form>
            )
        }
        </div>
    )
}

export default Admin1;